import React from "react";
import { Link } from "react-router-dom";

export default function Index() {
  return (
    <div className="col-sm-3 dashboard-sidebar">
      <ul className="dashboard-sidebar-list">
        <Link to="views">
          <li className="dashboard-sidebar-unit">Views</li>
        </Link>
        <Link to="location">
          <li className="dashboard-sidebar-unit">Location</li>
        </Link>
      </ul>
    </div>
  );
}
