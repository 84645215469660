import React from "react";
import { Link } from "react-router-dom";
import price_and_cost from "../../../assets/images/Price_and_Cost.png";
import UpKing_Music from "../../../assets/images/UpKing-Music.png";
import Studious from "../../../assets/images/Studious_landing.png";
import MetaTags from "react-meta-tags";

const Work = () => {
  return (
    <div className="work">
      <MetaTags>
        <title>Our Projects | Discover Digital</title>
        <meta
          name="description"
          content="See just a small sample of the dozens of websites that Discover Digital has builts and maintained over the last decade."
        />
      </MetaTags>
      <div className="banner_container">
        <div className="banner">
          <h1>Work</h1>
        </div>
      </div>
      <div className="list" id="content">
        {/* ITEM */}
        <div className="item">
          <div className="information_container">
            <h2>Price & Cost</h2>
            <div className="tags">
              <button>Ruby On Rails</button>
              <button>Angular</button>
              <button>HTML/CSS</button>
            </div>
            <p>
              Price & Cost proved to be quite a quite a challenge, but we love
              challenges. Our work behind the scenes led to significant
              improvements in the speed of the complex calculations that are
              core to this website.
            </p>
            <div className="action_btn">
              <Link to="/project">
                <button>View Project</button>
              </Link>
            </div>
          </div>
          <div className="card">
            <img
              src={price_and_cost}
              alt="Screenshot from the website Price & Cost. It shows a page with various numbers representing budget, internal cost, etc. It also shows a graph for these figures over time."
            />
          </div>
        </div>
        {/* ITEM */}
        <div className="item">
          <div className="information_container">
            <h2>UpKing Music</h2>
            <div className="tags">
              <button>Wordpress</button>
              <button>Javascript</button>
              <button>HTML/CSS</button>
            </div>
            <p>
              The client requested something simple and easy to use to showcase
              their work as a musician, and that's what we gave them. The UpKing
              website is a blazing fast wordpress site carefully crafted to suit
              the artist's brand.
            </p>
            <div className="action_btn">
              <Link to="/project">
                <button>View Project</button>
              </Link>
            </div>
          </div>
          <div className="card">
            <img
              src={UpKing_Music}
              alt="Screenshot from the website for UpKing Music Studio. The main part of the image is a stock photo of a keyboard and headphones, text overlaid that says 'Music from Reggae Dancehall Artist S. Coop'"
            />
          </div>
        </div>
        {/* ITEM */}
        <div className="item">
          <div className="information_container">
            <h2>Studious</h2>
            <div className="tags">
              <button>Ruby On Rails</button>
              <button>React</button>
              <button>HTML/CSS</button>
            </div>
            <p>
              Shhhh, Studious is not quite ready to show off yet, but this work
              in progress may be the next big thing in fostering talent. Check
              back again soon to see it in action.
            </p>
            <div className="action_btn">
              <Link to="/project">
                <button>View Project</button>
              </Link>
            </div>
          </div>
          <div className="card">
            <img
              src={Studious}
              alt="Screenshot for the website Studious. The main header of the site says 'Feedback from the world's best. Photos of a young woman and a young man also feature prominently on the page."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;
