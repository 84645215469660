import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { UserStatusContext } from "../context/Context";
import ScrollToTop from "./helpers/ScrollToTop";

import Navbar from "./header/Header";
import MainNavbar from "./header/main/MainNavbar";
import AdminNavbar from "./header/admin/Index";

import Main from "./main/Main";
import Home from "./main/home/Home";
import About from "./main/about/About";
import Services from "./main/services/Services";
import Work from "./main/work/Work";
import Process from "./main/process/Process";
import Contact from "./main/contact/Contact";
import PrivacyPolicy from "./main/pages/PrivacyPolicy";
import CookiePolicy from "./main/pages/CookiePolicy";
// import Project from "./main/project/Project";

import Footer from "./footer/Footer";

import Admin from "./admin/Index";
import Status404 from "./helpers/status/Status404.jsx";
import useScript from "./hooks/useScript";
import ahoy from "ahoy.js";

export default function App(props) {
  const [userStatus, setUserStatus] = useState("NOT_LOGGED_IN");
  const [user, setUser] = useState({});

  async function checkLoginStatus() {
    const res = await fetch("/logged_in", { credentials: "include" });
    const data = await res.json();
    return data;
  }

  useEffect(() => {
    checkLoginStatus()
      .then((data) => {
        if (data.logged_in && userStatus === "NOT_LOGGED_IN") {
          setUserStatus("LOGGED_IN");
          setUser(data.user);
        } else if (!data.logged_in && userStatus === "LOGGED_IN") {
          setUserStatus("NOT_LOGGED_IN");
          setUser({});
        }
      })
      .catch((err) => console.log(err));
    ahoy.trackView();
  }, []);

  useScript(
    "https://cdn-cookieyes.com/client_data/060ec473bf2a1e7297b4ea96/script.js"
  );
  return (
    <>
      <ScrollToTop />
      <UserStatusContext.Provider
        value={{
          userStatus,
          setUserStatus,
          user,
          setUser,
        }}
      >
        <Routes>
          <Route element={<Navbar />}>
            <Route path="/*" element={<MainNavbar />} />
            <Route path="/admin/*" element={<AdminNavbar />} />
          </Route>
        </Routes>
        <Routes>
          <Route path="/" element={<Main />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="work" element={<Work />} />
            <Route path="services" element={<Services />} />
            <Route path="process" element={<Process />} />
            <Route path="contact" element={<Contact />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="cookie-policy" element={<CookiePolicy />} />
            {/* <Route path="project" element={<Project />} /> */}
          </Route>
          <Route index element={<Home />} />
          <Route path="/admin/*" element={<Admin />} />
          <Route path="*" element={<Status404 />} />
        </Routes>
        <Footer />
      </UserStatusContext.Provider>
    </>
  );
}
