import { useState } from "react";

export default function useDate(dateEnd, setDateEnd, getRange) {
  const [startDate, setStartDate] = useState(new Date());
  const limit = new Date(new Date().toDateString()).getTime();

  function handleDateLeft(e) {
    setDateEnd((prevState) => {
      const newDate = new Date(
        new Date(prevState).getTime() - 1000 * 60 * 60 * 24
      );
      setStartDate(newDate);
      return newDate;
    });
  }

  function handleDateRight(e) {
    const date = new Date(dateEnd.toDateString()).getTime();
    if (date === limit) return;
    setDateEnd((prevState) => {
      const newDate = new Date(
        new Date(prevState).getTime() - 1000 * 60 * 60 * 24 * -1
      );
      setStartDate(newDate);
      return newDate;
    });
  }

  function handleDatePicker(date) {
    if (new Date(date.toDateString()).getTime() > limit) return;
    setDateEnd(getRange(new Date(date)));
    setStartDate(date);
  }

  return {
    startDate: startDate,
    handleDatePicker: handleDatePicker,
    handleDateLeft: handleDateLeft,
    handleDateRight: handleDateRight,
  };
}
