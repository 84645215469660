import React, { useState } from "react";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import logo from "../../../assets/images/logo.png";

export default function Index() {
  const [show, setShow] = useState(false);

  const handleToggle = (e) => {
    setShow((prev) => !prev);
  };

  return (
    <nav className="navbar">
      <img src={logo} />
      <h3>Discover Digital</h3>
      <Navigation show={show} setShow={setShow} />
      <div className="toggle_btn">
        <GiHamburgerMenu onClick={handleToggle} />
      </div>
    </nav>
  );
}

function Navigation({ show, setShow }) {
  const handleClick = (e) => {
    if (e.target.nodeName !== "A" && e.target.nodeName !== "BUTTON") return;
    setShow(false);
  };

  return (
    <div
      className={`navigation_container${show ? " mobile_navigation" : ""}`}
      onClick={handleClick}
    >
      <ul>
        <li>
          <Link to={"/"}>Home</Link>
        </li>
        <li>
          <Link to={"/about"}>About</Link>
        </li>
        <li>
          <Link to={"/work"}>Work</Link>
        </li>
        <li>
          <Link to={"/services"}>Services</Link>
        </li>
        <li>
          <Link to={"/process"}>Process</Link>
        </li>
      </ul>
      <div className="action_btn">
        <Link to={"/contact"}>
          <button>Contact</button>
        </Link>
      </div>
    </div>
  );
}
