import React from "react";
import { useUserStatus } from "../../../context/Context";
import { Link } from "react-router-dom";
import useNav from "../../hooks/useNav";

export default function Index() {
  const { bgColor, scrollColor, scrollLogo } = useNav();
  const status = useUserStatus();

  function handleLogout() {
    const csrf = document.querySelector("[name=csrf-token]").content;
    fetch("/logout", {
      method: "DELETE",
      headers: { "X-CSRF-TOKEN": csrf },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        status.setUserStatus("NOT_LOGGED_IN");
        status.setUser({});
      })
      .catch((err) => console.log(err)); //what to do with error//redirect to appropriate page: 404 0r 422.  That does not apply in this case. Need to continue as normal
  }

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light"
      style={{ backgroundColor: bgColor }}
    >
      <div className="container-fluid">
        <Link to={"/"}>
          <img
            className="navbar-brand"
            src={scrollLogo}
            alt="website logo: Discover Digital"
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarNavDropdown"
        >
          <ul className="navbar-nav me-5">
            <li className="nav-item">
              <Link
                to={"/admin"}
                className="nav-link"
                style={{ color: scrollColor }}
              >
                Home
              </Link>
            </li>

            {status.userStatus === "LOGGED_IN" && (
              <li className="nav-item">
                <Link
                  to={"/"}
                  className="nav-link"
                  onClick={handleLogout}
                  style={{ color: scrollColor }}
                >
                  Log Out
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
}
