import React from "react";
import { Link } from "react-router-dom";
import gabriela from "../../../assets/images/Gabriela_Grant.jpg";
import MetaTags from "react-meta-tags";

const About = () => {
  return (
    <div className="about">
      <MetaTags>
        <title>About | Discover Digital</title>
        <meta
          name="description"
          content="Learn more about the leadership and history of Discover Digital and their multi-industry expertise."
        />
      </MetaTags>
      <Story />
      <Team />
    </div>
  );
};

export default About;

function Story() {
  return (
    <div className="our_story_container" id="content">
      <div className="our_story">
        <div className="info_container">
          <div className="title_section">
            <h3>
              <span>Our Story</span>
            </h3>
            <p>We take the worry out of web development.</p>
          </div>
          <div className="text_section">
            <p>
              But if you prefer to lose sleep over your website, we’re probably
              not for you. We are a creative team of digital problem solvers who
              love simplifying the complex. <br />
              <br />
              Through our web expertise, we deliver peace of mind to you and
              your business. Wouldn't you sleep better knowing you have a
              secure, custom and optimized website? We trust you would. Our
              delight is being the unseen support behind your business success.
            </p>
          </div>
        </div>
        <div className="stats_container">
          <div>
            <h1>
              <span>10+</span>
            </h1>
            <p>Years Experience</p>
          </div>
          <div>
            <h1>
              <span>20+</span>
            </h1>
            <p>Industries worked in</p>
          </div>
          <div>
            <h1>
              <span>100+</span>
            </h1>
            <p>Projects Completed</p>
          </div>
          <div>
            <h1>
              <span>5+</span>
            </h1>
            <p>Team Members</p>
          </div>
        </div>
      </div>
    </div>
  );
}

function Team() {
  return (
    <div className="team_container">
      <div className="team">
        <h1>Meet the Founder</h1>
        <div className="item">
          <div className="information_container">
            <h2>Gabriela Grant</h2>
            <div className="tag">
              <button>Founder / Senior Software Developer</button>
            </div>
            <p>
              Discover Digital Inc. emerged when Gabriela recognized the need to
              help small and medium sized businesses secure an online presence
              with impact.
              <br />
              <br /> With expertise in managing websites, strategically
              expanding the presence of existing ones, or building custom sites
              from the ground up, Gabriela taps into her love for programming to
              create long-lasting value through bespoke website experiences. Her
              10 plus years of service as a web developer and, now, senior
              software developer has enabled Discover Digital to make inroads in
              numerous industries, including education, healthcare, real estate,
              financial services, e-commerce, hospitality, manufacturing, and more. <br />
              <br />
              To help businesses better navigate the ever-complex and
              rapidly-changing digital landscape, Gabriela leads a team of
              highly-skilled and dedicated specialists, who take pride in making
              every website they touch, the best it can be.
            </p>
            <div className="action_btn">
              <Link to={"/contact"}>
                <button>Contact Now</button>
              </Link>
            </div>
          </div>
          <div className="card">
            <div className="card_image_wrapper">
              <img
                src={gabriela}
                alt="A profile picture of the founder, Gabriela. She is standing at an angle with her arms crossed, and wearing a blue dress shirt."
              />
            </div>
          </div>
        </div>
        {/* <div className="list_container" style={{ display: "none" }}>
          <h3>
            <span>Core Team</span>
          </h3>
          <ul>
            <li>
              <span>Crystal Waters</span>
              <span>Designer</span>
            </li>
            <li>
              <span>Michael Andor</span>
              <span>Developer</span>
            </li>
            <li>
              <span>Ashley Baker</span>
              <span>Project Manager</span>
            </li>
            <li>
              <span>Jaylen Matthews</span>
              <span>Graphic Designer</span>
            </li>
            <li>
              <span>Iyana Pierce</span>
              <span>Developer</span>
            </li>
          </ul>
        </div> */}
      </div>
    </div>
  );
}
