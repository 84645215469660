import React from "react";
import SkipToContent from "../helpers/SkipToContent";
import { Outlet } from "react-router-dom";

export default function Index() {
  return (
    <header className="header">
      <SkipToContent />
      <Outlet />
    </header>
  );
}
