import { useState, useEffect } from "react";
import { feature } from "topojson-client";

const jsonUrl = "https://unpkg.com/world-atlas@2.0.2/countries-50m.json";
export default function useData() {
  const [data, setData] = useState(null);

  async function getData() {
    const res = await fetch(jsonUrl);
    return await res.json();
  }

  function handleGetRange() {
    getData()
      .then((data) => {
        const { countries } = data.objects;
        setData(feature(data, countries));
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    handleGetRange();
  }, []);
  return data;
}