import React from "react";
import { useUserStatus } from "../../context/Context";
import { Navigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { IoMdWarning } from "react-icons/io";
import Alert from "../helpers/Alert";
import FormField from "./FormField";
import useForm from "../hooks/useForm";
import BackModal from "./BackModal";

export default function SignIn(props) {
  const { userStatus } = useUserStatus();
  if (userStatus === "LOGGED_IN") return <Navigate to={"/admin/dashboard"} />;

  const {
    inputs,
    errors,
    msg,
    token,
    recaptchaRef,
    handleReCAPTCHAChange,
    handleChange,
    handleSubmit,
  } = useForm(
    { email: "", password: "" },
    { email: "* Required", password: "* Required" },
    "user",
    "/sessions",
    "/admin/dashboard"
  );

  return (
    <div className="auth-form-container">
      <Alert msg={msg} styles="alert-warning alert-dismissible">
        <IoMdWarning /> <strong>IMPORTANT!</strong>
      </Alert>
      <form onSubmit={handleSubmit} className="auth-form">
        <h2 className="form-title">Sign In Form</h2>
        <FormField
          label={"Email"}
          type={"email"}
          name={"email"}
          placeholder={"Email..."}
          onChange={handleChange}
          required={true}
          value={inputs.email}
          error={errors.email}
        />
        <FormField
          label={"Password"}
          type={"password"}
          name={"password"}
          placeholder={"Password..."}
          onChange={handleChange}
          required={true}
          value={inputs.password}
          error={errors.password}
        />
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
          ref={recaptchaRef}
          onChange={handleReCAPTCHAChange}
        />
        <button
          type="submit"
          className="btn btn-primary my-3"
          disabled={!token}
        >
          Sign In
        </button>
      </form>
    </div>
  );
}
