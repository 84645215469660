import React, { useRef } from "react";
import { useLocation } from "react-router-dom";

export default function SkipToContent() {
  const skipRef = useRef();
  const location = useLocation();

  function handleKeyDown(e) {
    if (e.keyCode !== 13 || location.pathname.startsWith("/admin")) return;
    document.getElementById("content").focus();
    skipRef.current.style.top = "-200px";
  }

  return (
    <a
      href="#content"
      className="skip-to-content"
      onKeyDown={handleKeyDown}
      ref={skipRef}
    >
      Skip To Main Content
    </a>
  );
}
