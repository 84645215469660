import React from "react";

export default function Countries({ stats }) {
  return (
    <div className="location__information">
      <h2 className="location__title">Countries</h2>
      <p className="location__total">Countries: {stats?.countries?.length}</p>
      <div className="location__table">
        <table className="dashboard__table">
          <thead>
            <tr className="table__header">
              <th>Country</th>
              <th>Visits</th>
            </tr>
          </thead>
          <tbody>
            {stats?.countries?.map((key) => {
              return (
                <tr key={key.country}>
                  <td>{key.country}</td>
                  <td>{key.visits}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
