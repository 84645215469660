import React from "react";
import MetaTags from 'react-meta-tags';

const PrivacyPolicy = () => {
  return (
    <div className="policy">
      <h1 className="privacy-policy-h1">Privacy Policy</h1>

      <p>Last Updated On 30-Oct-2022</p>
      <p>Effective Date 30-Oct-2022</p>


      <p className="privacy-policy-p">
        This Privacy Policy describes the policies of
      </p>

      <div>
        Discover Digital Incorporated
      </div>
      <div>Level 1, One Welches</div>
      <div>Welches, Barbados</div>
      <div>BB22025</div>
      <p>email: info@discoverdigital.tech</p>
      <p>phone: 2462619566</p>

      <p>
        on the collection,
        use and disclosure of your information that we collect
        when you use our website ( https://discoverdigital.tech ).
        (the “Service”). By accessing or using
        the
        Service, you are consenting to the collection, use and
        disclosure of your information in accordance with this
        Privacy Policy. If you do not consent to the same,
        please do not access or use the Service.
      </p>

      <p className="privacy-policy-p">
        We may modify this Privacy Policy at any time without
        any prior notice to you and will post the revised
        Privacy Policy on the Service. The revised Policy will
        be effective 180 days from when the
        revised Policy is posted in the Service and your
        continued access or use of the Service after such time
        will constitute your acceptance of the revised Privacy
        Policy. We therefore recommend that you periodically
        review this page.
      </p>

      <ol className="privacy-policy-ol">
        <li>
          <h2 className="privacy-policy-h2">
            Information We Collect:
          </h2>
          <p className="privacy-policy-p">
            We will collect and process the following
            personal information about you:
          </p>
          <ol className="privacy-policy-ol">
            <li>Name</li>
            <li>Email</li>
            <li>Mobile</li>
          </ol>
        </li>
        <li>
          <h2 className="privacy-policy-h2">
            How We Use Your Information:
          </h2>
          <p className="privacy-policy-p">
            We will use the information that we collect
            about you for the following purposes:
          </p>
          <ol className="privacy-policy-ol">
            <li>Support</li>
          </ol>
          <p className="privacy-policy-p">
            If we want to use your information for any other
            purpose, we will ask you for consent and will
            use your information only on receiving your
            consent and then, only for the purpose(s) for
            which grant consent unless we are required to do
            otherwise by law.
          </p>
        </li>
        <li>
          <h2 className="privacy-policy-h2">
            Retention Of Your Information:
          </h2>
          <p className="privacy-policy-p">
            We will retain your personal information with us
            for
            90 days to 2 years after users terminate their accounts
            or for as
            long as we need it to fulfill the purposes for
            which it was collected as detailed in this
            Privacy Policy. We may need to retain certain
            information for longer periods such as
            record-keeping / reporting in accordance with
            applicable law or for other legitimate reasons
            like enforcement of legal rights, fraud
            prevention, etc. Residual anonymous information
            and aggregate information, neither of which
            identifies you (directly or indirectly), may be
            stored indefinitely.
          </p>
        </li>
        <li>
          <h2 className="privacy-policy-h2">
            Your Rights:
          </h2>
          <p className="privacy-policy-p">
            Depending on the law that applies, you may have
            a right to access and rectify or erase your
            personal data or receive a copy of your personal
            data, restrict or object to the active
            processing of your data, ask us to share (port)
            your personal information to another entity,
            withdraw any consent you provided to us to
            process your data, a right to lodge a complaint
            with a statutory authority and such other rights
            as may be relevant under applicable laws. To
            exercise these rights, you can write to us at
            gabriela@discoverdigitalweb.com.
            We will respond to your
            request in accordance with applicable law.
          </p>

          <p className="privacy-policy-p">
            You may opt-out of direct marketing
            communications or the profiling we carry out for
            marketing purposes by writing to us at
            gabriela@discoverdigitalweb.com.
          </p>

          <p className="privacy-policy-p">
            Do note that if you do not allow us to collect
            or process the required personal information or
            withdraw the consent to process the same for the
            required purposes, you may not be able to access
            or use the services for which your information
            was sought.
          </p>
        </li>
        <li>
          <h2 className="privacy-policy-h2">
            Cookies Etc.
          </h2>
          <p className="privacy-policy-p">


            To learn more about how we use these
            and your choices in relation to these tracking
            technologies, please refer to our
            <a href="https://discoverdigital.tech/cookie-policy">Cookie Policy.</a>
          </p>
        </li>

        <li>
          <h2 className="privacy-policy-h2">
            Security:
          </h2>
          <p className="privacy-policy-p">
            The security of your information is important to
            us and we will use reasonable security measures
            to prevent the loss, misuse or unauthorized
            alteration of your information under our
            control. However, given the inherent risks, we
            cannot guarantee absolute security and
            consequently, we cannot ensure or warrant the
            security of any information you transmit to us
            and you do so at your own risk.
          </p>
        </li>

        <li>
          <h2 className="privacy-policy-h2">
            Third Party Links & Use Of Your Information:
          </h2>
          <p className="privacy-policy-p">
            Our Service may contain links to other websites
            that are not operated by us. This Privacy Policy
            does not address the privacy policy and other
            practices of any third parties, including any
            third party operating any website or service
            that may be accessible via a link on the
            Service. We strongly advise you to review the
            privacy policy of every site you visit. We have
            no control over and assume no responsibility for
            the content, privacy policies or practices of
            any third party sites or services.
          </p>
        </li>

        <li>
          <h2 className="privacy-policy-h2">
            Grievance / Data Protection Officer:
          </h2>
          <p className="privacy-policy-p">
            If you have any queries or concerns about the
            processing of your information that is available
            with us, you may email our Grievance Officer at
            Discover Digital Incorporated,
            email: info@discoverdigitalweb.com.
            We will address your concerns in accordance with applicable law.
          </p>
        </li>
      </ol>
    </div>
  );
};

export default PrivacyPolicy;