import { useState } from "react";
import logo from "../../assets/images/LOGO WHITE.png"; //will move this to css
import colorLogo from "../../assets/images/LOGO FULL COLOUR.png";

export default function useNav() {
  const [bgColor, setBgColor] = useState("#302f43");
  const [scrollColor, setScrollColor] = useState("white");
  const [scrollLogo, setScrollLogo] = useState(logo);

  document.body.onscroll = function (e) {
    if (window.pageYOffset > 0) {
      setBgColor("white");
      setScrollColor("black");
      setScrollLogo(colorLogo);
    } else {
      setBgColor("#302f43");
      setScrollColor("white");
      setScrollLogo(logo);
    }
  };
  return {
    bgColor: bgColor,
    scrollColor: scrollColor,
    scrollLogo: scrollLogo,
  };
}
