import React from "react";
import { Routes, Route } from "react-router-dom";
import Views from "./views/Index";
import Location from "./location/Index";

export default function Index({ globe }) {
  return (
    <div className="col-sm-9 dashboard-section">
      <Routes>
        <Route index element={<Views />} />
        <Route path="views" element={<Views />} />
        <Route path="location/*" element={<Location globe={globe} />} />
      </Routes>
    </div>
  );
}
