import React from "react";
import MetaTags from "react-meta-tags";

const Process = () => {
  return (
    <div className="process">
      <MetaTags>
        <title>Our Process | Discover Digital</title>
        <meta
          name="description"
          content="Learn more about Discover Digital's process for taking a website project from a plan to reality."
        />
      </MetaTags>
      <div className="process_header">
        <h3>
          <span>Process</span>
        </h3>
        <p>
          We love taking the worry out of web development. With simple steps, we
          bring about your desired results.
        </p>
      </div>
      <div className="process_body">
        <div className="process_body_info">
          <p>Let’s Show You</p>
          <div className="process_body_large_text">
            <div>how</div>
          </div>
        </div>
        <div className="process_body_list">
          <div className="process_body_card">
            <h2>Discover</h2>
            <p>
              The journey to your hassle-free website starts at our discovery
              meetings. Bring your most pressing questions. Learn about our
              process. And tell us your business goals. From there, we can
              determine the best technology to support those objectives.
            </p>
          </div>
          <div className="process_body_card">
            <h2>Design</h2>
            <p>
              Our wireframe stage is where we get a bird's-eye view of your
              site's layout. Here we ask, how does it function? This helps us
              understand how users will actually experience your site. Then we
              develop around those insights. Along the way, you have
              opportunities to review or even request changes. We're not happy
              until you are.
            </p>
          </div>
          <div className="process_body_card">
            <h2>Develop</h2>
            <p>
              And now the fun part! We build your site and bring in all that
              we've learned before to ensure it's exactly what you need. Take
              your site for a private test drive and let's discuss the features
              that you love. Or those that might need some slight revisions.
              It's all about the little details, and we intend to get everything
              right.
            </p>
          </div>
          <div className="process_body_card">
            <h2>Deliver</h2>
            <p>
              We take pride in working with you and answering all your questions
              or concerns. Our four-step process keeps you involved with every
              aspect of the site launch. We enjoy giving full visibility. All
              systems go? It's countdown time!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Process;
