import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { validate, validateErrors } from "../../utils/Index";

export default function useForm(
  inputsObj,
  errorsObj,
  attribute,
  url,
  path,
  cb
) {
  const [inputs, setInputs] = useState(inputsObj);
  const [errors, setErrors] = useState(errorsObj);
  const [msg, setMsg] = useState("");
  const [token, setToken] = useState("");
  const recaptchaRef = useRef();
  const error500 =
    "Your message could not be sent at this time.  Try again later";
  const navigate = useNavigate();

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((prevState) => ({ ...prevState, [name]: value }));
    validateErrors(errors, name, value);
    setErrors(errors);
  }

  const handleReCAPTCHAChange = (value) => {
    setToken(value);
  };

  function handleSubmit(e) {
    e.preventDefault();
    window.scrollTo(0, 0);
    if (!token) {
      setMsg("You must fill in recaptcha");
      return;
    }
    if (!validate(errors)) return;

    const csrf = document.querySelector("[name=csrf-token]").content;
    recaptchaRef.current.reset();

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": csrf,
      },
      credentials: "include",
      body: JSON.stringify({
        [attribute]: { ...inputs, token: token },
      }),
    })
      .then((res) => {
        if (res.status === 200 || res.status === 422) return res.json();
      })
      .then((data) => {
        console.log("happening", data);
        // setMsg(data.message)
        if (!data.status)
          throw new Error("Unprocessable", { cause: data.message });
        navigate(path, {
          state: { msg: data.message, user: data.user },
          replace: true,
        });
        cb && cb({ email: inputs.email });
      })
      .catch((err) => {
        setMsg(err.cause ? err.casue : error500);
        setInputs(inputsObj);
        setErrors(errorsObj);
        recaptchaRef.current.reset();
      });
  }

  return {
    inputs: inputs,
    errors: errors,
    msg: msg,
    setMsg: setMsg,
    token: token,
    recaptchaRef: recaptchaRef,
    handleReCAPTCHAChange: handleReCAPTCHAChange,
    handleChange: handleChange,
    handleSubmit: handleSubmit,
  };
}
