import React from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import price_and_cost from "../../../assets/images/Price_and_Cost.png";
import UpKing_Music from "../../../assets/images/UpKing-Music.png";
import Studious from "../../../assets/images/Studious_landing.png";
import Alert from "../../helpers/Alert";
import MetaTags from 'react-meta-tags';

export default function Index(props) {
  const location = useLocation();
  const navigate = useNavigate();

  function handleClick(e) {
    navigate({ state: "" });
  }

  return (
    <>
      <MetaTags>
        <title>Home | Discover Digital</title>
        <meta name="description" content="Discover Digital specializes in designing, developing, and maintaining websites for small to medium sized businesses." />
      </MetaTags>
      <Alert
        msg={location.state?.msg}
        setMsg={handleClick}
        backgroundColor={"#d4edda"}
        borderColor={"#c3e6cb"}
        color={"#155724"}
      >
        SUCCESS!
      </Alert>
      <Banner />
      <Services />
      <Stack />
      <Work />
    </>
  );
}

function Banner() {
  return (
    <div className="home_banner_container">
      <div className="banner">
        <div className="title">
          Discover
          <br /> Develop <br />
          <span className="gradient">Deliver.</span>
        </div>
        <div className="text">We take the worry out of web development.</div>
        <div className="action_btn">
          <Link to={"/work"}>
            <button> See Our Work</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

function Services() {
  return (
    <div className="home_services_container" id="content">
      <div className="description">
        <div className="container">
          <h2 className="title">Our Services</h2>
          <p className="text">
            We develop and design with your business in mind
          </p>
          <Link to={"/services"}>
            <button>View Services</button>
          </Link>
        </div>
      </div>
      <div className="list">
        <div className="card">
          <h3>Web Development</h3>
          <p>
            We handle every aspect of building, maintaining and managing a
            website. Whether you want a platform-based website, such as
            Wordpress, or a custom-built one with all the specific capabilities
            you need, our team is ready to work with you. Our goal is to help
            your business grow by ensuring your...
          </p>
        </div >
        <div className="card">
          <h3>Security</h3>
          <p>
            In today’s digital age, security breaches are a serious threat to
            businesses of all sizes. Our security expertise and controls address
            potential risks that can make your site vulnerable to threats of any
            kind. Every site we build or maintain is taken through strict
            security checks and protocols. We use...
          </p>
        </div>
        <div className="card">
          <h3>Accessibility</h3>
          <p>
            An often overlooked but important aspect of website design is
            accessibility. Through this service, we configure websites to enable
            individuals with disabilities or impairments to use them as easily
            and successfully as all others. The last thing you want is for your
            site to lose potential customers, because of inaccessibility...
          </p>
        </div>
      </div >
    </div >
  );
}

function Stack() {
  return (
    <div className="home_our_stack_container">
      <h3>Our Stack</h3>
      <p>Our weapons of choice, and we use the best of them.</p>
      <div className="grid">
        <div>Ruby On Rails</div>
        <div>PHP</div>
        <div>HTML</div>
        <div>Javascript</div>
        <div>CSS3</div>
        <div>React</div>
        <div>Wordpress</div>
        <div>VueJS</div>
        <div>SQL</div>
      </div >
    </div >
  );
}

function Work() {
  return (
    <div className="home_our_work_container">
      <h1>Our Work</h1>
      <div className="item">
        <div className="information_container">
          <h2>Price & Cost</h2>
          <div className="tags">
            <button>Ruby On Rails</button>
            <button>Angular</button>
            <button>HTML/CSS</button>
          </div>
          <p>
            Price & Cost proved to be quite a quite a challenge, but we love challenges. Our work behind the scenes led to significant improvements in the speed of the complex calculations that are core to this website.
          </p>
          <div className="action_btn">
            <Link to="/project">
              <button>View Project</button>
            </Link>
          </div>
        </div>
        <div className="card">
          <img src={price_and_cost} alt="Screenshot from the website Price & Cost. It shows a page with various numbers representing budget, internal cost, etc. It also shows a graph for these figures over time." />
        </div>
      </div >
      <div className="item">
        <div className="information_container">
          <h2>UpKing Music</h2>
          <div className="tags">
            <button>Wordpress</button>
            <button>Javascript</button>
            <button>HTML/CSS</button>
          </div>
          <p>
            The client requested something simple and easy to use to showcase their work as a musician, and that's what we gave them. The UpKing website is a blazing fast wordpress site carefully crafted to suit the artist's brand.
          </p>
          <div className="action_btn">
            <Link to="/project">
              <button>View Project</button>
            </Link>
          </div>
        </div>
        <div className="card">
          <img src={UpKing_Music} alt="Screenshot from the website for UpKing Music Studio. The main part of the image is a stock photo of a keyboard and headphones, text overlaid that says 'Music from Reggae Dancehall Artist S. Coop'" />
        </div>
      </div >
      <div className="item">
        <div className="information_container">
          <h2>Studious</h2>
          <div className="tags">
            <button>Ruby On Rails</button>
            <button>React</button>
            <button>HTML/CSS</button>
          </div>
          <p>
            Shh, Studious is not quite ready to show off yet, but this work in progress may be the next big thing in fostering talent. Check back again soon to see it in action.
          </p>
          <div className="action_btn">
            <Link to="/project">
              <button>View Project</button>
            </Link>
          </div>
        </div>
        <div className="card">
          <img src={Studious} alt="Screenshot for the website Studious. The main header of the site says 'Feedback from the world's best. Photos of a young woman and a young man also feature prominently on the page." />
        </div>
      </div >
    </div >
  );
}
