import React from "react";
import { Routes, Route } from "react-router-dom";
import Main from "./main/Index";
import Dashboard from "./dashboard/Index";
import SignInForm from "../form/SignInForm";
//import SignUpForm from "../form/SignUpForm";

export default function Admin(props) {
  return (
    <>
      <Routes>
        <Route index element={<Main />} />
        <Route path="dashboard/*" element={<Dashboard />} />
        {/* <Route path="sign_up" element={<SignUpForm />} /> */}
        <Route path="sign_in" element={<SignInForm />} />
      </Routes>
    </>
  );
}
