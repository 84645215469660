import React from "react";

export default function FormField(props) {
  return (
    <div className="form-group my-3">
      <label htmlFor={props.name} className="form-label">
        {props.label}
      </label>
      <input
        type={props.type}
        name={props.name}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        required={props.required}
        className="form-control"
      />
      <p className="form-error-msg">{props.error}</p>
    </div>
  );
}
