import React, { useEffect } from "react";
// import { useUserStatus } from "../../../context/Context";
import { useLocation } from "react-router-dom";
import Success from "./success/Index";
import useData from "../../hooks/useData"

export default function DashBoard(props) {
  // const status = useUserStatus();
  const globe = useData();
  const location = useLocation();
  console.log("we good", location);
  // useEffect(() => {
  //   if (location.state) {
  //     status.setUserStatus("LOGGED_IN");
  //     status.setUser(location.state.user);
  //   }
  // }, []);

  return (
    <Success
      // status={status.userStatus}
      globe={globe}
      msg={location?.state?.msg}
    />
  );
}
