import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";

export default function Index() {
  return (
    <div className="footer">
      <div className="navigation_container">
        <div className="contact_container">
          <img src={logo} alt="Discover Digital's logo - a capital D" />
          <p>Let's hear from you. <br /> Our team is here to help.</p>
          <Link to={"/contact"}>
            <button>Contact</button>
          </Link>
        </div>
        <div className="navigation_list_container">
          <ul>
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>
              <Link to={"/services"}>Services</Link>
            </li>
            <li>
              <Link to={"/about"}>About</Link>
            </li>
            <li>
              <Link to={"/process"}>Process</Link>
            </li>
            <li>
              <Link to={"/work"}>Work</Link>
            </li>
            <li>
              <Link to={"/contact"}>Contact</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="legal_container">
        <div>
          {" "}
          &copy; {new Date().getFullYear()} Discover Digital Inc. All rights
          reserved. | &nbsp;
          <Link to={"/privacy-policy"}>Privacy Policy</Link>
          &nbsp;| &nbsp;
          <Link to={"/cookie-policy"}>Cookie Policy</Link>
        </div>
        {/* <div>Site Credits</div> */}
      </div>
    </div>
  );
}
