import React from "react";
import { useNavigate } from "react-router-dom";
import { BsCheckCircleFill } from "react-icons/bs";
import Alert from "../../../helpers/Alert";
import SideBar from "./sidebar/Index";
import Display from "./display/Index";

export default function Index({ status, msg, globe }) {
  const navigate = useNavigate();

  function handleClick(e) {
    navigate({ state: "" });
  }

  return (
    <main className="dashboard">
      <Alert
        msg={msg}
        handleClick={handleClick}
        styles="alert-warning alert-dismissible"
      >
        <BsCheckCircleFill /> Success!
      </Alert>
      <div className="row dashboard-view">
        <SideBar />
        <Display globe={globe} />
      </div>
    </main>
  );
}
