import React from "react";

export default function InfoBar({ stats }) {
  return (
    <div className="dashboard-info row">
      <div className="col-sm-3 dashboard-total-container">
        <div
          className="dashboard-total"
          style={{ height: "100%", width: "100%" }}
        >
          <h6>Total page views:</h6>
          <p>{stats.total_pageviews}</p>
        </div>
      </div>
      <div className="col-sm-3 dashboard-unique-container">
        <div className="dashboard-unique">
          <h6>Unique page views:</h6>
          <p>{stats.unique_pageviews}</p>
        </div>
      </div>
      <div className="col-sm-6 dashboard-device-container">
        <div className="dashboard-device">
          <h6>Devices</h6>
          <div>Desktop: {stats?.desktop}</div>
          <div>Mobile: {stats?.mobile}</div>
        </div>
      </div>
    </div>
  );
}
