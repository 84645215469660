import React from "react";
import logo from "../../assets/images/logo.png";

export default function Alert({
  msg,
  setMsg,
  children,
  backgroundColor,
  borderColor,
  color,
}) {
  const handleClick = () => {
    setMsg("");
  };

  return (
    <div className={`alert_container ${msg ? "show_alert" : ""}`}>
      <div
        className="alert"
        style={{
          backgroundColor: backgroundColor,
          color: color,
          borderColor: borderColor,
        }}
      >
        <div>
          <strong>{children}</strong> {msg}{" "}
        </div>
        <button type="button" onClick={handleClick}>
          x
        </button>
      </div>
    </div>
  );
}
