import React from "react";
import { Link } from "react-router-dom";
import { useUserStatus } from "../../../context/Context";

export default function Main() {
  const status = useUserStatus();

  return (
    <main>
      <div className="" style={{ height: "100vh" }}>
        <div className="jumbotron jumbotron-fluid bg-transparent">
          <div className="container secondary-color">
            <h1 className="display-4">Admin Home Page</h1>
            <p className="lead">You are now in Admin {status.userStatus}</p>
            <hr className="my-4" />
            {status.userStatus === "NOT_LOGGED_IN" && (
              <div className="border col">
                <Link
                  to="/admin/sign_in"
                  className="btn btn-lg custom-button border m-2"
                  role="button"
                >
                  Sign In
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}
