import React from "react";
import Controls from "./Controls";
import Loading from "./Loading";
import Days from "./Days";
import Hours from "./Hours";

export default function Graph({
  stats,
  handleDatePicker,
  handleDateLeft,
  handleDateRight,
  startDate,
  dateEnd,
  getRange,
  loading,
  days,
  setDays,
}) {
  return (
    <div className="dashboard-graph">
      <Controls
        startDate={startDate}
        dateEnd={dateEnd}
        getRange={getRange}
        handleDatePicker={handleDatePicker}
        handleDateLeft={handleDateLeft}
        handleDateRight={handleDateRight}
        days={days}
        setDays={setDays}
      />
      <div className="graph-container">
        {days ? <Days stats={stats} /> : <Hours stats={stats} />}
      </div>

      <Loading loading={loading} />
    </div>
  );
}
