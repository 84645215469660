import React from "react";
import { Link } from "react-router-dom";

export default function Ips({ stats }) {
  return (
    <>
      <p className="location__total">Ips: {stats?.countries?.length}</p>
      <div className="location__table">
        <table className="dashboard__table">
          <thead>
            <tr className="table__header">
              <th>Ip</th>
            </tr>
          </thead>
          <tbody>
            {stats?.ips?.map((key) => {
              return (
                <tr key={key}>
                  <td>
                    <Link to={key}>{key}</Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
