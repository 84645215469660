import React from "react";

export default function Loading({ loading }) {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "#444",
        position: "absolute",
        top: 0,
        display: loading ? "grid" : "none",
        placeItems: "center",
        opacity: ".5",
      }}
    >
      <div className="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
