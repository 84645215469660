import React from "react";
import DatePicker from "react-datepicker";
import { ImArrowRight } from "react-icons/im";
import { ImArrowLeft } from "react-icons/im";

export default function Controls({
  handleDatePicker,
  handleDateLeft,
  handleDateRight,
  startDate,
  dateEnd,
  getRange,
  days,
  setDays,
}) {
  function handleDays() {
    setDays((prevState) => !prevState);
  }
  return (
    <div className="row dashboard-controls">
      <DatePicker selected={startDate} onChange={handleDatePicker} />{" "}
      <div className="dashboard-controls-btns">
        <button onClick={handleDays} className="dashboard-controls-toggle">
          {days ? "Time" : "Days"}
        </button>
        <button onClick={handleDateLeft} className="dashboard-controls-arrows">
          <ImArrowLeft />
        </button>
        <button onClick={handleDateRight} className="dashboard-controls-arrows">
          <ImArrowRight />
        </button>
      </div>
      <div className="dashboard-range">
        <span>{getRange(dateEnd, 6).toDateString()}</span>
        {" -- "}
        <span>{dateEnd.toDateString()}</span>
      </div>
    </div>
  );
}
