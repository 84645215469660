import React from "react";
import { Outlet } from "react-router-dom";

export default function IpContainer() {
  return (
    <div className="location__information">
      <h2 className="location__title">IP Addresses</h2>
      <Outlet />
    </div>
  );
}
