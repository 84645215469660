import React from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import Popper from "popper.js";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Routes from "../routes/Index";

document.addEventListener("DOMContentLoaded", () => {
  ReactDOM.render(
    <>{Routes}</>,
    document.body.appendChild(document.createElement("div"))
  );
});
