import React, { useState } from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

export default function Services() {
  return (
    <div className="services">
      <div className="banner_container">
        <div className="banner">
          <h1 className="banner_title">Services</h1>
        </div>
      </div>
      <div className="list">
        <Service service={"Web Development"}>
          We handle every aspect of building, maintaining and managing a
          website. Whether you want a platform-based website, such as Wordpress,
          or a custom-built one with all the specific capabilities you need, our
          team is ready to work with you. Our goal is to help your business grow
          by ensuring your website speed, overall performance and user
          experience are the best they can be.
        </Service>
        <Service service={"Security"}>
          {" "}
          In today's digital age, security breaches are a serious threat to
          businesses of all sizes. Our security expertise and controls address
          potential risks that can make your site vulnerable to threats of any
          kind.Every site we build or maintain is taken through strict security
          checks and protocols.We use the latest technologies to protect your
          site from these threats.Yet, it's no one - and - done step.Security is
          a continuous process that, when handled correctly, continually
          updates, reviews and backs up your site data.Our processes allow us to
          quickly spot weaknesses that could be exploited, if left unchecked.{" "}
          <br />
          <br />
          Additionally, we recognized long ago that security processes are only
          one component of keeping websites safe.We want to help you and your
          team stay safe online, as well.Today, hackers commonly manipulate
          people through social engineering.Using specific techniques, they
          often can get access to a company's confidential information.Here's
          where we saw an opportunity to work more intently with you.In our
          maintenance package, we even include training your employees on how to
          spot common social engineering tricks that may lead to security
          breaches. <br />
          <br />
          Depending on the types of data your site handles, your company may
          need extra security, though.Does your company process payments, or
          have a high volume of transactions ? Does your company handle
          government identification numbers or health information ? These types
          of transactions can be made even more secure through encryption to
          protect their privacy.Not exactly sure ? Contact our team of experts
          to help discover how secure and legally compliant your site is.From
          there, we will get your site secure and well protected from bots and
          hackers, or any other malicious security threats.
        </Service>
        <Service service={"Accessibility"}>
          An often overlooked but important aspect of website design is
          accessibility. Through this service, we configure websites to enable
          individuals with disabilities or impairments to use them as easily and
          successfully as all others. The last thing you want is for your site
          to lose potential customers, because of inaccessibility.
          <br />
          <br /> Our team is dedicated to ensuring every website we develop
          meets the standards for WCAG 2.0 Level AA global compliance, for
          usability and understandability. And depending on the size, nature of
          your business and requirements by law, we can also bring your site to
          accessibility conformance at the highest level–WCAG 2.0 Level AAA–if
          needed. Some 90% of websites today do not have basic accessibility
          standards.
          <br />
          <br /> Yours does not have to be one of them. Contact us to discuss
          how our accessibility expertise can give your business an edge over
          its competitors.
        </Service>
        <Service service={"Integrations"}>
          Website integrations are the ideal way to manage the needs of a growing
          business in the digital age. Whether you need to collect leads, manage
          social media channels, generate shipping labels, or perform any multitude
          of other tasks, there are ways to optimise those actions. As your business
          grows, your website's capabilities should too. But this should not come at
          the cost of being overwhelmed with multiple tools and interfaces from day to day.
          <br />
          <br />
          Done correctly, your website can have an easy-to-use admin setup that is
          carefully crafted to automate processes, where possible, and keep your
          business on the cutting edge of efficiency. The end result is that integrations
          save you time and money. Our team is ready to show you exactly how integrations
          can help restore some peace of mind.

        </Service>
        <Service service={"Responsive Design"}>
          Did you know that more than half of all web traffic today is accessed
          through a mobile device? When a website does not have this adaptive
          flexibility, the site is not user-friendly. Websites that are not user
          friendly are easily forgettable and they can lose potential customers quickly.
          Responsive design ensures that your website is designed with all screen sizes
          in mind - whether mobile devices, tablets, or larger screens. This often
          easily-overlooked element of web design is what can separate your site from
          others and keep it from feeling outdated.
        </Service>
        <Service service={"Search Engine Optimization"}>
          In a highly-competitive digital landscape, it is easy to get lost in a sea of
          thousands and thousands of business websites that may be similar to yours.
          Without careful attention to search engine optimization (SEO), your website
          can easily get lost in that sea. At Discover Digital, we don't just believe
          in building beautiful, secure and efficient websites, we want those websites
          to be easily discovered and to rank highly in all searches.
          <br />
          <br />
          Your website SEO cannot survive on merely keywords and descriptions alone.
          To stand out and to draw in your ideal customers/clients, your website needs
          to load quickly, be responsive across all devices and rank at the top levels
          of searches on Google and other platforms. This is why SEO is a specific and
          intentional strategy that no successful business online can afford to overlook.
          Let's get started today on improving your business' visibility and search ranking.
        </Service>
        <Service service={"Hosting"}>
          Through our dedicated hosting services, Discover Digital provides computing and storage
          resources to help give your business a competitive edge. With various formats
          available—dedicated hosting, shared hosting or cloud hosting—our team will gladly help
          you discover what service is best for you. With experience over a wide range of hosting
          providers, we will dedicate our expertise to finding one suitable for your needs and budget.
        </Service>
        <Service service={"QA & Testing"}>
          Working hand in hand with our rigorous security protocols, through this service we thoroughly
          test and check websites for optimal functionality. QA & Testing helps to ensure that your
          business website is free of any bugs that can be extremely costly to fix if left undetected.
          It is that extra level of assurance that adds value to your business brand and its online presence.
        </Service>
      </div>
    </div>
  );
}

function Service({ service, children }) {
  const [expand, setExpand] = useState(false);

  const handleExpand = (e) => {
    const height = expand ? "204px" : "auto";
    // const target = e.target.parentElement.previousSibling.clientHeight;
    // if (!expand && target === 204 && service === "Web Development") return;
    e.target.parentElement.previousSibling.style.height = height;
    setExpand((prev) => !prev);
  };

  return (
    <div className="card_wrapper">
      <div className="card">
        <div className="card_header">
          <h2>{service}</h2>
        </div>
        <div className="card_text">
          <p>{children}</p>
          <button onClick={handleExpand}>
            <>
              {expand ? <AiOutlineMinus /> : <AiOutlinePlus />}
              <div></div>
            </>
          </button>
        </div>
      </div>
    </div>
  );
}
