import React from "react";
import useForm from "../../hooks/useForm";
import ahoy from "ahoy.js";
import ReCAPTCHA from "react-google-recaptcha";
import Alert from "../../helpers/Alert";
import MetaTags from "react-meta-tags";

export default function Contact(props) {
  const {
    inputs,
    errors,
    msg,
    setMsg,
    token,
    recaptchaRef,
    handleReCAPTCHAChange,
    handleChange,
    handleSubmit,
  } = useForm(
    { email: "", message: "", first_name: "", last_name: "", phone: "" },
    {
      email: "*",
      message: "*",
      first_name: "*",
      last_name: "*",
    },
    "contact",
    "/contacts",
    "/",
    (obj) => {
      ahoy.track("contacted", obj);
    }
  );

  return (
    <>
      <MetaTags>
        <title>Contact | Discover Digital</title>
        <meta
          name="description"
          content="Contact Discover Digital today to find out how we can help grow your business' presence on the web."
        />
      </MetaTags>
      <Alert
        msg={msg}
        setMsg={setMsg}
        backgroundColor={"#f8d7da"}
        borderColor={"#f5c6cb"}
        color={"#721c24"}
      >
        ALERT!
      </Alert>
      <div className="contact">
        <div className="contact_info">
          <h3>Contact</h3>
          <p>Let’s hear from you. Our team is here to help</p>
        </div>
        <div className="contact_form_container">
          <form onSubmit={handleSubmit}>
            <div className="contact_form_item_container">
              <div className="contact_form_item">
                <label htmlFor="first_name">
                  First Name<sup>{errors.first_name}</sup>
                </label>
                <input
                  type="text"
                  name="first_name"
                  placeholder="enter first name"
                  value={inputs.first_name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="contact_form_item">
                <label htmlFor="last_name" className="">
                  Last Name<sup>{errors.last_name}</sup>
                </label>
                <input
                  type="text"
                  name="last_name"
                  placeholder="enter last name"
                  value={inputs.last_name}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="contact_form_item_container">
              <div className="contact_form_item">
                <label htmlFor="email">
                  Email<sup>{errors.email}</sup>
                </label>
                <input
                  type="email"
                  name="email"
                  placeholder="enter email"
                  value={inputs.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="contact_form_item">
                <label htmlFor="phone">
                  Phone<sup></sup>
                </label>
                <input
                  type="tel"
                  name="phone"
                  placeholder="enter phone number"
                  value={inputs.phone}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="contact_form_textarea_container">
              <label htmlFor="message">
                Message<sup>{errors.message}</sup>
              </label>
              <textarea
                name="message"
                value={inputs.message}
                placeholder="Tell us what you're looking for."
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <div className="recaptch_container">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
                onChange={handleReCAPTCHAChange}
                ref={recaptchaRef}
              />
            </div>
            <div className="contact_form_submit_btn_container">
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
