import React from "react";

export default function Table({ table, total }) {
  return (
    <div className="dashboard-table">
      <table className="table table-bordered">
        <tbody>
          <tr>
            <th></th>
            <th>Pages</th>
            <th>Page views</th>
            <th>% Page views</th>
          </tr>
          {table &&
            Object.keys(table).map((key) => {
              return (
                <tr key={parseInt(key) + 1}>
                  <td>{parseInt(key) + 1}</td>
                  {table[key].map((element, i) => {
                    return (
                      <React.Fragment key={i}>
                        <td>{element}</td>
                        {!isNaN(element) && (
                          <td>{((element / total) * 100).toFixed(2)}</td>
                        )}
                      </React.Fragment>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}
