import React, { useState, useEffect } from "react";
import useDate from "../../../../../hooks/useDate";
import Graph from "./Graph";
import InfoBar from "./InfoBar";
import Table from "./Table";

export default function Index() {
  const [dateEnd, setDateEnd] = useState(getRange(new Date()));
  const { startDate, handleDatePicker, handleDateLeft, handleDateRight } =
    useDate(dateEnd, setDateEnd, getRange);
  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(true);
  const [days, setDays] = useState(true);

  function getRange(range, offset = 0) {
    const date = new Date(range);
    return new Date(date.getTime() - offset * 1000 * 60 * 60 * 24);
  }

  async function getData() {
    const res = await fetch(
      `/pageview_analytics/${new Date(dateEnd).getTime()}`
    );
    return await res.json();
  }

  function handleGetRange() {
    setLoading(true);
    getData()
      .then((data) => {
        console.log("mo", data);
        setStats(data.stats);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    handleGetRange();
  }, [dateEnd]);

  return (
    <div className="dashboard-section-wrapper">
      <Graph
        stats={stats}
        handleGetRange={handleGetRange}
        startDate={startDate}
        dateEnd={dateEnd}
        getRange={getRange}
        handleDatePicker={handleDatePicker}
        handleDateLeft={handleDateLeft}
        handleDateRight={handleDateRight}
        loading={loading}
        days={days}
        setDays={setDays}
      />
      <InfoBar stats={stats} />
      <Table table={stats.pages} total={stats.total_pageviews} />
    </div>
  );
}
