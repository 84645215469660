import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

export default function Hours({ stats }) {
  return (
    <ResponsiveContainer width="500%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={stats.hourViews}
        margin={{
          top: 5,
          right: 30,
          left: -15,
          bottom: -20,
        }}
      >
        <CartesianGrid strokeDasharray="1" horizontal="true" vertical="" />
        <XAxis
          tickLine={true}
          xAxisId={0}
          dy={0}
          dx={-0}
          label={{ value: "", angle: 0, position: "bottom" }}
          interval={0}
          dataKey="hour"
          tick={{ fontSize: 9, angle: 0 }}
        />

        <XAxis
          xAxisId={1}
          dy={-15}
          dx={0}
          label={{ value: "", angle: 0, position: "bottom" }}
          interval={23}
          dataKey="date"
          tickLine={false}
          tick={{ fontSize: 9, angle: 0 }}
          axisLine={false}
        />

        <YAxis />
        <Tooltip />
        <Line
          type="linear"
          dataKey="hour_views"
          stroke="#82ca9d"
          isAnimationActive={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
