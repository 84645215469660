/******************* FORM VALITATION CODE *****************/
/**********************************************************/

export const validate = (errors) => {
  let pass = true;
  Object.values(errors).forEach((value) => {
    value.length > 0 && (pass = false);
  });

  return pass;
};

export const validateErrors = (errors, name, value) => {
  const reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/;
  const PASSWORD_MSG =
    "Must be at least 8 characters, have at least 1 lowercase, uppercase, and special character";

  switch (name) {
    case "email":
      if (value === "") {
        errors.email = "*";
      } else if (!/^\S+@\S+\.[a-zA-Z]{2,4}$/.test(value)) {
        errors.email = "*";
      } else {
        errors.email = "";
      }
      break;

    // case "password":
    //   if (value === "") {
    //     errors.password = "* Required";
    //   } else if (!reg.test(value)) {
    //     errors.password = PASSWORD_MSG;
    //   } else {
    //     errors.password = "";
    //   }
    //   break;

    case "message":
      if (value.length < 7) {
        errors.message = "*";
      } else {
        errors.message = "";
      }
      break;

    case "first_name":
      if (value === "") {
        errors.first_name = "*";
      } else {
        errors.first_name = "";
      }
      break;

    case "last_name":
      if (value === "") {
        errors.last_name = "*";
      } else {
        errors.last_name = "";
      }
      break;

    default:
      break;
  }
};

/**********************************************************/
/******************* FORM VALITATION CODE *****************/
