import React, { useEffect, useRef } from "react";
import {
  geoEqualEarth,
  geoPath,
  select,
  zoomIdentity,
  zoomTransform,
  pointer,
  zoom,
} from "d3";
import Loading from "../views/Loading";

export default function Svg({ globe }) {
  const rectRef = useRef();
  const svgRef = useRef();

  useEffect(() => {
    console.log("log", globe);
    if (!globe) return;
    const svg = select(svgRef.current);
    const { width, height } = rectRef.current.getBoundingClientRect();
    const projection = geoEqualEarth().fitSize([width, height], globe);
    const path = geoPath().projection(projection);
    const zoomy = zoom().scaleExtent([1, 20]).on("zoom", zoomed);

    svg.attr("viewBox", [0, 0, width, height]).on("click", reset);

    const g = svg.append("g");

    const land = g
      .append("g")
      .attr("fill", "#444")
      .attr("cursor", "pointer")
      .selectAll("path")
      .data(globe.features)
      .join("path")
      .attr("class", "country")
      .on("click", clicked)
      .attr("d", path);

    land.append("title").text((d) => d.properties.name);

    g.append("path")
      .attr("fill", "none")
      .attr("stroke", "white")
      .attr("stroke-linejoin", "round");

    svg.call(zoomy);

    function reset() {
      land.transition().style("fill", null);
      svg
        .transition()
        .duration(750)
        .call(
          zoomy.transform,
          zoomIdentity,
          zoomTransform(svg.node()).invert([width / 2, height / 2])
        );
    }

    function clicked(event, d) {
      const [[x0, y0], [x1, y1]] = path.bounds(d);
      event.stopPropagation();
      land.transition().style("fill", null);
      select(this).transition().style("fill", "red");

      const c_n = 0.9 / Math.max((x1 - x0) / width, (y1 - y0) / height);
      let min = 8;
      if (20 < c_n < 40) min = 20;
      if (40 < c_n < 120) min = 40;
      if (120 < c_n) min = 100;

      svg
        .transition()
        .duration(750)
        .call(
          zoomy.transform,
          zoomIdentity
            .translate(width / 2, height / 2)
            .scale(Math.min(min, c_n))
            .translate(-(x0 + x1) / 2, -(y0 + y1) / 2),
          pointer(event, svg.node())
        );
    }

    function zoomed(event) {
      const { transform } = event;
      g.attr("transform", transform);
      g.attr("stroke-width", 1 / transform.k);
    }
  }, [globe]);

  return (
    <div
      style={{
        width: "100%",
        height: 500,
        position: "relative",
        marginBottom: 10,
      }}
      ref={rectRef}
    >
      {globe ? (
        <svg style={{ width: "100%", height: 500 }} ref={svgRef} />
      ) : (
        <Loading loading={true} />
      )}
    </div>
  );
}
