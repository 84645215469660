import React, { useState, useEffect } from "react";
import Svg from "./Svg";
import Countries from "./Countries";
import IpContainer from "./IpContainer";
import Ips from "./Ips";
import Ip from "./Ip";
import Referrers from "./Referrers";
import { Routes, Route } from "react-router-dom";

// CLEAN UP CODE
export default function Index({ globe }) {
  const [stats, setStats] = useState({});

  async function getData() {
    const res = await fetch(`/location_analytics`);
    return await res.json();
  }

  function handleGetRange() {
    getData()
      .then((data) => {
        console.log("mo", data);
        setStats(data.stats);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    handleGetRange();
  }, []);

  return (
    <div className="dashboard-section-wrapper">
      <Svg globe={globe} />
      <Countries stats={stats} />
      <Routes>
        <Route element={<IpContainer />}>
          <Route index element={<Ips stats={stats} />} />
          <Route path=":ip" element={<Ip stats={stats} />} />
        </Route>
      </Routes>
      <Referrers stats={stats} />
    </div>
  );
}
