import React from "react";

export default function Referrers({ stats }) {
  return (
    <div className="location__information">
      <h2 className="location__title">Referrers</h2>
      <p className="location__total">referrers: {stats?.referrer?.length}</p>
      <div className="location__table">
        <table className="dashboard__table">
          <thead>
            <tr className="table__header">
              <th>Referrer</th>
            </tr>
          </thead>
          <tbody>
            {stats?.referrer?.map((key, i) => {
              return (
                <tr key={key.referrer}>
                  <td>{key.referrer}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
