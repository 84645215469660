import React from "react";
import { Outlet } from "react-router-dom";

export default function Index() {
  return (
    <main className="main" id="content">
      <Outlet />
    </main>
  );
}
