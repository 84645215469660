import React from "react";
import image from "../../../assets/images/404.png";

export default function Status404() {
  return (
    <div className="" style={{ height: "100vh", textAlign: "center" }}>
      <h1>404</h1>
      <img
        src={image}
        className="status-image"
        style={{ height: "150px", width: "150px" }}
      />
      <h3 className="status-message">Page not found. Check url.</h3>
    </div>
  );
}
