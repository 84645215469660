import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { GoArrowLeft } from "react-icons/go";

export default function Ip() {
  const params = useParams();
  const navigate = useNavigate();
  const [ips, setIps] = useState({});

  function handleBack(e) {
    navigate(-1);
  }

  async function getData() {
    const res = await fetch(`/ip_analytics/${params.ip.replace(/\./g, "_")}`);
    return await res.json();
  }

  function handleGetRange() {
    getData()
      .then((data) => {
        console.log("ip", data);
        setIps(data);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    handleGetRange();
  }, []);
  return (
    <div>
      <button onClick={handleBack} className="ip__btn">
        <GoArrowLeft />
      </button>
      <h5>{params.ip}</h5>
      <div className="ip__info">
        {ips?.ip?.map((v, i) => {
          return (
            <div className="ip__info__item" key={i}>
              <ul>
                <li>
                  <div>Number</div>
                  <div>{i + 1}</div>
                </li>
                <li>
                  <div>Browser:</div>
                  <div>{v.browser}</div>
                </li>
                <li>
                  <div>City:</div>
                  <div>{v.city}</div>
                </li>
                <li>
                  <div>Country:</div>
                  <div>{v.country}</div>
                </li>
                <li>
                  <div>Device Type:</div>
                  <div>{v.device_type}</div>
                </li>
                <li>
                  <div>Latitude:</div>
                  <div>{v.latitude}</div>
                </li>
                <li>
                  <div>Longitude:</div>
                  <div>{v.longitude}</div>
                </li>
                <li>
                  <div>Os:</div>
                  <div>{v.os}</div>
                </li>
                <li>
                  <div>Referrer:</div>
                  <div>{v.referrer}</div>
                </li>
                <li>
                  <div>Region:</div>
                  <div>{v.region}</div>
                </li>
                <li>
                  <div>Started At:</div>
                  <div>{v.started_at}</div>
                </li>
                <li>
                  <div>User Agent:</div>
                  <div>{v.user_agent}</div>
                </li>
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
}
