import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

export default function Days({ stats }) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={stats.pageViews}
        margin={{
          top: 5,
          right: 30,
          left: -15,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="1" horizontal="true" vertical="" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Line
          type="linear"
          dataKey="page_views"
          stroke="#82ca9d"
          isAnimationActive={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
